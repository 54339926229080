@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  /* .Waste-Dashboard-Container {
    @apply flex flex-col items-center justify-center;
  } */
  /* .Waste-Filter-Container {
    @apply flex flex-row justify-between items-center ml-64;
  }
  .Waste-Level-Chart-Container {
    @apply flex flex-row justify-center items-center mt-8;
  }
  .Filter-Buttons {
    @apply px-8;
  }

  .Total-Waste-Container {
    @apply flex flex-col items-start justify-center ml-64 mt-6;
  }
  .Total-Waste-h1 {
    @apply text-gray-600;
  }
  .Number-of-Wastes {
    @apply font-semibold text-[18px] tracking-widest;
  }
  .Waste-Increase {
    @apply text-green-600 text-sm;
  }

  .Waste-Items-Container {
    @apply ml-64 mt-6;
  }
  .Waste-Items-Header {
    @apply flex flex-row justify-between p-2;
  }
  .Waste-Items-h1 {
    @apply text-xl;
  }
  .Recently-Added {
    @apply flex items-center hover:text-plaex-green cursor-pointer;
  }
  .Recently-Added-h1 {
    @apply mr-5;
  }
  .Recently-Added-icon {
    @apply mr-2;
  }
  .See-All-Container {
    @apply flex justify-end mr-6 -mt-4 text-sm items-center text-gray-700 hover:text-plaex-green cursor-pointer;
  }
  .See-All-Right-Arrow-Icon {
    @apply ml-5 text-lg;
  } */
}
