@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @layer components {
  .Cell-Property {
    @apply border-b-2 px-4 py-2;
  }
  .header {
    @apply text-black;
  }
  .Table {
    @apply table-auto w-full text-gray-600 text-center text-[15px];
  }
  .Table-Container {
    @apply overflow-x-auto p-5;
  }
} */

.bin-table-option{
  align-items: center;
  justify-content: space-between;
} 

.bin-table-select{
  margin-left: auto;
  padding-right: 48px;
}

@media (max-width: 599px) {
  .bin-table-option{
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  } 

  /* .bin-table-select{
    margin-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  } */

  .bin-table-container{
    /* border: .1px solid rgb(168, 168, 168); */
    margin-top: 24px;
  } 
  .my-progress-bar {
    background: green !important;
  }
}
