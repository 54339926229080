@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
  
  @layer components{
    .selected {
      @apply bg-plaex-light-green border-none
    }
  }
  

#binTable table tr{
    border-bottom: .5px solid #CBCBCB;
    height:56px;
}

#binTable table tbody tr:hover{
    background-color: #c9ffd54a;
    border-bottom: .5px solid #006D00;
    color: #006D00;
}


td:nth-child(4) div{
    height: 40px;
    /* width: 45%; */
    /* margin: auto; */
    /* padding: 0px 24px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    /* background-color: #c9ffd5d9; */
    /* color: #006D00; */
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

tbody tr td{
    padding: 0px 3% ;
}

#binTable h2{
    color: #006D00;
}

.seeBinAll{
    color: #818181;
    cursor: pointer;
}

.seeBinAll:hover{
    border-bottom: .5px solid #818181;

}

