@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
  
  @layer components{
    .selected {
      @apply bg-plaex-light-green border-none
    }
  }
  

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.Slide{
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.SlideOut {
  animation: slideOut 0.5s ease-in-out;
}

