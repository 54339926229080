@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  /* .Waste-Dashboard-Container {
    @apply flex flex-col items-center justify-center;
  } 
.Waste-Filter-Container {
    @apply flex flex-row justify-between items-center ml-64 mt-32
  }
  .Waste-Level-Chart-Container {
    @apply flex flex-row justify-center items-center mt-8;
  } */
  .Filter-Buttons{
    @apply px-8
    
  }

  .Total-Waste-Container{
    /* @apply flex flex-col items-start justify-center ml-64 mt-6 */
  }
  .Total-Waste-h1{
    @apply text-gray-600
  }
  .Number-of-Wastes{
    @apply font-semibold text-[18px] tracking-widest
  }
  .Waste-Increase{
    @apply text-green-600 text-sm
  }

  .Waste-Items-Container{
    /* @apply ml-64 mt-6 */
  }
  
  .Waste-Items-Header{
    @apply flex flex-row justify-between p-2
  }
  .Waste-Items-h1{
    @apply text-xl
  }
  .Recently-Added{
    @apply flex flex-col items-center cursor-pointer
  }
  .Recently-Added-h1{
    @apply mr-5
  }
  .Recently-Added-icon{
    @apply mr-2
  }
  .See-All-Container{
    @apply flex justify-end mr-6 -mt-4 text-sm items-center text-gray-700 hover:text-plaex-green cursor-pointer
  }
  .See-All-Right-Arrow-Icon{
    @apply ml-5 text-lg
  } 

  .selected {
    @apply bg-plaex-light-green border-none
  }
}

#wasteDashboard{
  margin-top: 120px;
  width: 100vw;
}


.Waste-Filter-Container{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.Waste-Filter-Container-title{
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
}

.Filter-Buttons{
  padding: 0;
}

.Filter-Buttons button{
  margin-bottom: 8px;
}

.Total-Waste-Container{
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
}

.Waste-Items-Container{
  margin-top: 32px;
}

.pt-16 {
    padding-top: 4rem;
    margin-top: -70px;
}

/* .select {
  appearance: none;
  background-color: #e5f5e8;
  border: none;
  border-radius: 0.375rem;
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
}

.select option {
  padding: 0.25rem 0.75rem;
} */
