@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components{
    .selected {
      @apply bg-plaex-light-green border-none

  }
}

#binLevel {
  display: flex;
  flex-direction: column;
}

.binChart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.seeFillAll {
  color: #818181;
  cursor: pointer;
}

.waste-level-chart{
  margin-left: 8px;
}

/* ------------------------
.waste-count{
  margin: 24px 0px;
  display: flex;
  align-items: center;
}

*/
.Number-of-Wastes{
  margin-left: 24px;
}


/* responsivity */
.waste-level-chart-content{
  height: 300px;
}

#binLevelChart {
  height: 100% !important;
  width: 100% !important;
}
@media (max-width: 599px) {
  .bin-chart-options{
    flex-direction: column ;
  }

  .bin-options{
    margin-top: 24px;
  }


  .waste-level-chart{
    margin-left: 0px;
  }



  .bin-options button{
    margin-bottom: 8px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
