@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Poppins', sans-serif;
}

@font-face {
    font-family: 'Poppins';
    src: url("../src/Assets/fonts/Poppins-Regular.ttf");
}