.textarea-transition {
  transition: all 4s ease-in-out;
}
.loading_icon {
  animation: spin 1s linear infinite;
}
.my-progress-bar {
  background: green !important;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(200%);
  }
}


.SlideIn{
  animation: slideIn 0.5s ease-in-out;
}

.SlideOut{
  animation: slideOut 0.5s ease-in-out;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {

}