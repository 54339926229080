.blur {
  pointer-events: none;
  user-select: none;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 200ms;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(100%);
  transition: transform 200ms;
}
.tooltip {
  max-width: 200px; 
  word-wrap: break-word;
}
