.react-calendar {
    width: 350px;
    max-width: 100%;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    line-height: 1.125em;
    text-align: center;
  }
  *{
    font-family: 'Poppins', sans-serif;
  }
  
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button[disabled] {
    background-color: transparent;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 10px;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #15803d;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background:gray;
    color: black;
    border-radius: 10px;
    border: 1px black solid;
  }

  .react-calendar__tile {
    padding: 10px;
    font-size: 14px;
    background-color: #c8f8d3;
  }

  .react-calendar__navigation__arrow,
.react-calendar__navigation__double-arrow {
  border: 1px solid #f9fafb; 
  border-radius: 10px;
  font-size: 1.5em;
  background-color: #86efac;
  padding: 3px;
}
.react-calendar__navigation__label {
    margin: 0 0.5em; 
  }

  .react-calendar__navigation__arrow:hover,
.react-calendar__navigation__double-arrow:hover,
.react-calendar__navigation__arrow:active,
.react-calendar__navigation__double-arrow:active {
  background-color: #c8f8d3; /* Change the background color on hover and active */
}

/* Default styles for desktop */
.react-calendar {
  width: 350px;
  max-width: 100%;
}

/* Styles for mobile devices */
@media (max-width: 480px) {
  .react-calendar {
    width: 100%;
    font-size: 0.8em;
  }
}


/* Responsive code  */

.rdrCalendarWrapper {
  width: 100%;
  
}

.rdrDateDisplayWrapper {
  flex-direction: column;
}

.rdrDateDisplayItem {
  width: 100%;
}

.rdrDateDisplayItem input {
  width: calc(100% - 32px);
}

.rdrDefinedRangesWrapper {
  display: none;
}

@media (min-width: 576px) {
  .rdrDateDisplayWrapper {
    flex-direction: row;
  }

  .rdrDateDisplayItem {
    width: auto;
  }

  .rdrDateDisplayItem input {
    width: auto;
  }

  .rdrDefinedRangesWrapper {
    display: block;
  }
}
/* Styles for small screens */
@media (max-width: 576px) {
  .rdrCalendarWrapper {
    /* display: flex;
    flex-direction: column; */
  }
}
.rdrSelected, .rdrInRange {
  background-color: green !important;
}
.rdrStartEdge, .rdrEndEdge {
  background-color: green !important;
}
.rdrDayHovered {
  border-color: green !important;
}
.rdrDefinedRangesWrapper .rdrStaticRange.rdrStaticRangeSelected {
  background-color: green !important;
}
.rdrToday {
  border-bottom: 2px solid green !important;
}

.rdrCalendarWrapper {
  background-color: white !important;
}


/* .rdrDefinedRangesWrapper {
     display: none;
    
} */

.rdrDateRangeWrapper .rdrDay:hover .rdrDayNumber span {
  border-color: green !important;
}

.rdrDateRangeWrapper .rdrDayToday .rdrDayNumber span:after {
  background-color: green;
}
.my-progress-bar {
  background: #008000 !important;
}



.rdrDefinedRangesWrapper.active * {
  color: white;
}
.rdrDefinedRangesWrapper button:active {
  color: white;
}


.rdrInputRanges{
  
}
.rdrInputRange:last-child{
  display: none;
}
