.backward:hover, .forward:hover {
    background-color: #C9FFD5;
    cursor: pointer;
    color: #004800;
}

.top, .bottom{
    width: 95vw;
    display: flex;
    gap: 16px;
}

.dateOutput{
    color: #004800;
}
@keyframes pingnonstop{
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}

.animate-pingnonstop{
    animation: pingnonstop 2s cubic-bezier(0,0,0.2,1) infinite;
}
#wasteChart{
    /* margin: 50px auto; */
    display: flex;
    /* gap: 8px; */
    /* align-items: center;
    justify-content: center; */
}

.mini-graph{
    height: 2vh;
    margin-left: -10px;
}

@media(max-width:599px){
    .WasteChartContainer {
        margin-right: 4px;
        margin-left: 4px;
    }


}

@media(max-width:769px){
    #wasteChart{
        flex-direction: column;
    }

    .waste-item{
        width: 90%;
    }

    .top, .bottom {
        width: 100%;
    }

}

@media(max-width:1024px){
    .waste-item-mobile{
        display: none;
    }
}

