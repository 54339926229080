@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
    .Cell-Property{
        @apply border-b-2 px-4 py-2
    }
    .header{
        @apply text-black
    }
    .Table{
        @apply table-auto w-full text-gray-600 text-center text-[15px]
    }
    .Table-Container{
        @apply overflow-x-auto p-5
    }
}