
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@layer components {
    .Waste-Chart-Container{
         @apply h-[300px]  
    }
}

.Waste-Chart-Container{
    margin-top: 40px;
}
.slide-enter {
    transform: translateX(-100%);
  }
  .slide-enter-active {
    transform: translateX(0);
    transition: transform 200ms;
  }
  .slide-exit {
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(-100%);
    transition: transform 200ms;
  }
  