@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .Button {
    @apply rounded-full outline-none object-contain px-5 py-2 shadow-sm cursor-pointer hover:bg-plaex-light-green
            text-sm mr-2 focus:outline-none focus:bg-plaex-light-green focus:bg-opacity-70 text-gray-600 border-[1px]
          border-gray-400 focus:border-none bg-white dark:text-black;
  }
}
