#fillLevel {
  display: flex;
  flex-direction: column;
}

#fillLevelCapacity {
  margin: 8px auto 0 auto;
  display: flex;
  gap: 25px;
}

.fillChart{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.level {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 5px;
}

.level #wasteFillLevel,
.level #binLevel {
  width: 1rem;
  height: 0.5rem;
  border-radius: 100px;
  border: 0.5px solid #004800;
}

.level #wasteFillLevel {
  background-color: #004800;
}

.level #binLevel {
  background-color: #c9ffd5;
}

.seeFillAll {
  color: #818181;
  cursor: pointer;
}

.seeFillAll:hover {
  border-bottom: 0.5px solid #818181;
}


/* responsivity */

@media (min-width: 640px) { 
  #fillLevelChart{
    width: 90%;
    height: 50%;
  }
}

@media (min-width: 768px) { }

@media (min-width: 1024px) { }

@media (min-width: 1280px) { }

@media (min-width: 1536px) { }

@media (max-width: 600px) {
  .chart-container {
    width: 300px;
    height: 200px;
  }
}
