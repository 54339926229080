@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
  
  @layer components{
    .selected {
      @apply bg-plaex-light-green border-none
    }
  }
  

#binTable table tr{
    border-bottom: .5px solid #CBCBCB;
    height:56px;
}

#binTable table tbody tr:hover{
    background-color: #c9ffd54a;
    border-bottom: .5px solid #006D00;
    color: #006D00;
}


td:nth-child(4) div{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

tbody tr td{
    padding: 0px 3% ;
}

#binTable h2{
    color: #006D00;
}

.seeBinAll{
    color: #818181;
    cursor: pointer;
}

.seeBinAll:hover{
    border-bottom: .5px solid #818181;

}

.status-cell {
  text-align: center; 
  vertical-align: middle; 
  padding: 10px; 
}

.container {
  width: 80%;
  margin: 0 auto;
}

.bin-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.online {
  background-color: #e0f7e0;
}

.offline {
  background-color: #f8e0e0;
}

.fill-levels {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.fill-level {
  margin-right: 20px;
  display: flex;
  align-items: center;
} 

.progress-bar {
  width: 80px;
  height: 5px;
  background-color: #ddd;
  border-radius: 30px;
  margin: 0 10px;
  position: relative;
}

.progress {
  width: 100%; 
  height: 100%;
  background-color: green; 
}

.icon {
  margin-right: 5px;
  font-size: 18px;
}

.status-indicator {
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.status-icon {
  margin-right: 5px;
  font-size: 20px;
}

.status-indicator.online {
  background-color: #d4edda;
  color: #155724;
}

.status-indicator.offline {
  background-color: #f8d7da;
  color: #721c24;
}

.container {
  width: 100%;
  padding: 10px;
  background-color: lightblue;
  overflow: auto;
  box-sizing: border-box;
}

.bold-green {
  color: darkgreen;
  font-weight: bold;
  font-size: 16px;
}

.fill-level-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.fill-level {
  display: flex;
  gap: 10px;
  align-items: center; 
}

.bold-green {
  font-weight: bold;
  color: green;
}

.progress-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.progress-bar {
  width: 150px; 
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: green;
  border-radius: 5px;
}

.icon {
  font-size: 20px; 
}

